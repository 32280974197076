import React from 'react';
import Layout from '../../components/layout';

import LetConnect from '../../components/common/lets-coonect';
import {products,ProductItem} from './index'

import product_0 from "../../assets/images/products/safercircle-innerpage-0.png"
import product_4 from "../../assets/images/products/safercircle-innerpage-1.png"
import product_5 from "../../assets/images/products/safercircle-innerpage-2.png"
import product_6 from "../../assets/images/products/safercircle-innerpage-3.png"

import tick_icon from "../../assets/images/icon-tick.png"

import './our-product.scss'
import Button from '../../components/common/button';


const metatag={
    "title":"Our Products"
}

const SaferCircle = () => {

    return(
        <Layout page="inner-product"  metatag={metatag}>

            <article className='inner-product' >

             {/* <DispalySection title={<>Leading the Way in Diverse Industries:<br /> Our Commitment to Innovation and Excellence</>} subtitle={<>From strategy to execution, we help you achieve success</>}/> */}
               
              <section className='introduction-section' data-theme={'light'} >

                <div className='section-container' >

                    <div className='container-content' >
                        <p className='title' > What is  <span > Safercircle </span> </p>
                        <div className='container-image' >
                            <div className='image-container' >
                                <img className='display-image' src={product_0} alt="" />
                            </div>
                        </div>
                        {/* <p className='description'> The Safer Circle application act as a citizen guard. leveraging the power of community engagement and advanced technology, the Safer circle application aims to create a safer and more secure environment for the users. </p> */}
                        <p className='description'> The Safer Circle application act as a citizen guard. leveraging the power of community engagement and advanced technology, the Safer circle application aims to create a safer and more secure environment for the users. </p>
                        <div className='bullet-point-container'>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src={tick_icon} alt="" />
                                        </div>
                                        <div className='bullet-text' > Citizens to report incidents or suspicious behaviour to the agents.</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src={tick_icon} alt="" />
                                        </div>
                                        <div className='bullet-text' > Respond to and prevent crime by providing real-time information.</div>
                                    </div>
                        </div>
                        {/* <ButtonLink to="/home/ai-operation-solution" label="Lean More" labelcolor="black" /> */}
                    </div>

                    <div className='container-image' >
                        <div className='image-container' >
                            <img className='display-image' src={product_0}  alt="" />
                        </div>
                    </div>

                </div>

              </section>

              <section className="product-section" data-theme="light" >

                <div className="section-container"  >

                    <p className='title'> Purpose </p>

                    <p className='description'> Safer circle application is a mobile application that allow citizens to report incidents or suspicious behaviour to the agents. The purpose of these apps is to increase community engagement and promote public safety by making it easier for people to report crimes or suspicious activity in their area. They can also help police departments to more effectively respond to and prevent crime by providing real-time information and intelligence from the agent.</p>
                    
                    <div className='inner-container' >
                        <img className='image-one' src={product_4} alt="" />
                        <div className='image-container' >
                            <img className='image-two' src={product_5} alt="" />
                            <img className='image-two' src={product_6} alt="" />
                        </div>
                    
                    </div>

                    <p className='title'> Scope </p>

                    <p className='description'>In general, such an application could be used to enhance community safety by allowing citizens to report suspicious activity, request assistance from local law enforcement, and receive updates on public safety issues. It could also be used to facilitate communication and coordination between community members, local authorities, and other relevant organizations. Additionally, it may also include features such as emergency alerts, crime mapping, and tips for personal safety.</p>
                
                </div>

              </section>

              <section className='explore-other-products-section' data-theme="light"> 

                <div className='section-container'>
                    
                    <div className='inner-container' >
                        <div className='head' ><p>EXPLORE OTHER PRODUCTS</p> 
                       <Button to="/our-products" type="button_more_products" />
                        </div>
                            <div className='body' >
                                <div className='image-container' >
                                    <ProductItem  product={products[0]} />
                                    <ProductItem  product={products[1]} />      
                                </div>
                            </div>
                    </div>
                
                </div>

              </section>

              <LetConnect darkicon to="/contact-us" title="Okay enough about us, Lets talk about you" buttonlabel="LET'S CONNECT"/>
                
            </article>

        </Layout>
    )
}
 
export default SaferCircle;