
import Button from "../../components/common/button";

import icon_it_consulting from "../../assets/images/icon-it-consulting.png"
import icon_cloud_services from "../../assets/images/icon-cloud-service.png"
import icon_data_service from "../../assets/images/icon-data-service.png"
import icon_managed_it_service from "../../assets/images/icon-managed-it-service.png"
import icon_quality_assurance from "../../assets/images/icon-quality-assurance.png"
import icon_resource_augmentation from "../../assets/images/icon-resource-augmentation.png"


const ServiceOfferings = () => {

    return (     
      <div className="container" >

        <div className="container-title"> 
        <div className="title"> Our Diverse <span> Service Offerings </span> </div> 
        <Button to="/our-services" type="explore_services" /> 
        </div>

        <div className='container-content'  >

            <div className='content-item' >
                <img className='item-icon' src={icon_it_consulting} alt="" />
                <p className='title' >IT CONSULTING </p>
                We work with you to develop and deliver a strategic IT roadmap that are aligned to your business goals, processes and existing system capabilities.
            </div>

            <div className='content-item' >
                <img className='item-icon' src={icon_managed_it_service} alt="" />
                <p className='title' >MANAGED IT SERVICE</p>
                Depending on your budget and requirement, we can manage your IT infrastructure by being a part of your in-house IT team.
            </div>

            <div className='content-item' >
                <img className='item-icon' src={icon_cloud_services} alt=""/>
                <p className='title' > CLOUD SERVICES </p>
                Customised Service Level Agreements to help you eliminate the capital expenditure involved in buying hardware/software and running on-site data centres and on-premises infrastructure.
            </div>

            <div className='content-item' >
                <img className='item-icon' src={icon_resource_augmentation} alt=""/>
                <p className='title' >RESOURCE AUGMENTATION </p>
                Support your technology resource requirement with a outsourcing strategy - providing you with expert talent who can work in-house with your team on a contracting basis or remotely, as required.
            </div>

            <div className='content-item' >
                <img className='item-icon' src={icon_data_service}alt="" />
                <p className='title' >DATA SERVICES </p>
                We specialize in turning data into actionable insights. Our innovative solutions unlock the power of your data, enabling informed decisions, process optimization, and business success.
            </div>

            <div className='content-item' >
                <img className='item-icon' src={icon_quality_assurance} alt="" />
                <p className='title' >QUALITY ASSURANCE</p>
                Our quality assurance and testing services ensure the reliability and performance of your products and systems, delivering a seamless user experience.
            </div>

        </div>

      </div>
  );

}
 
export default ServiceOfferings;