import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import Router from './router'
import './assets/sass/index.scss'


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

      <BrowserRouter>
        <Router/>
      </BrowserRouter>

);


