import React, { useEffect, useRef } from 'react';

import Layout from "../../components/layout";

import ManinBanner from './main-banner';
import ServiceOfferings from './service-offerings'
import AboutFreston from './about-freston';
import OurProduct from './our-products';
import LetConnectUS from './let-coonect-us'


export const sections = [
  {
    component: <ManinBanner />,
    clasname: 'main-banner',
    title: <>Latest News</>,
    theme: 'dark',
    headerColor: '#0B1F40',
    links: ``,
    linkaddres:`/who-we-are,/freston-proceed,/contact-us`
  },
  {
    component: <AboutFreston />,
    clasname: 'about-freston',
    title: <> About Us </>,
    theme: 'light',
    headerColor: 'white',
    links: '',
    linkaddres:`/who-we-are`
  },
  {
    component: <ServiceOfferings />,
    clasname: 'service-offerings',
    title: <> 5G and  3GPP <br/> Specification </>,
    theme: 'dark',
    headerColor: '#0B1F40',
    links: '',
    linkaddres:`/our-services`
  },
  {
    component: <OurProduct />,
    clasname: 'our-product-home',
    title: <> Our <br/> Products </>,
    theme: 'light',
    headerColor: 'white',
    links: '',
    linkaddres:`/our-products`
  },
  {
    component: <LetConnectUS />,
    clasname: 'let-connect',
    title: "Contact Us",
    theme: 'dark',
    headerColor: '#0B1F40',
    links: '',
    linkaddres:`/who-we-are`
  },
]

const Home = () => {

  const articleRef = useRef();

  useEffect(()=>{

    let productList = document.querySelectorAll(".product-list li");

    for (let i = 0; i < productList.length; i++) {

      productList[i].addEventListener("click", function () {
        let current = document.querySelectorAll(".product-list li.active");
        current[0].className = current[0].className.replace("active", "");
        this.className += "active";
      });

    }

  },[]);

  return (
    <Layout page="home" >

      <article id="fullpage" ref={articleRef} data-bs-spy="scroll" data-bs-target="#sectionsList" data-bs-offset="0">

          {
            sections.map((section,index) => {
              return (
                <section title="" key={index} id={section.clasname} className={section.clasname} background-color={section.headerColor} data-theme={section.theme} theme={section.theme}  links={section.links} linkaddres={section.linkaddres} >
                  {section.component}
                </section>
              )
            })
          }
      
      </article>

    </Layout>
  )

}

export default Home;