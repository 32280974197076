import leftImage from "../../assets/images/about-us-image.png"
import Button from '../../components/common/button';

import iso_logo_01 from '../../assets/images/iso-01.png'
import iso_logo_02 from '../../assets/images/iso-02.png'


import client_logo_01 from '../../assets/images/client-logo-01.png'
import client_logo_02 from '../../assets/images/client-logo-02.png'
import client_logo_03 from '../../assets/images/client-logo-03.png'
import client_logo_04 from '../../assets/images/client-logo-04.png'
import client_logo_05 from '../../assets/images/client-logo-05.png'


const AboutFreston = () => {
    
    return (
        <>

            <div className="whoAreWe-section" data-theme="light">
                <div className="container">
               

                        <div className="image-container">
                            <img
                                className="whoAreWe-image"
                                src={leftImage}
                                alt=""
                                title=""
                            />
                        </div>

                        <div className="content-container">

                            <h2 className="section-title">
                                We are <br/>
                                 <b> Mobisense</b> , a software technology company
                            </h2>
                            <p className="section-text">
                                We take technology forward - helping organisations innovate, scale and transform with bespoke digital products and services.
                            </p>

                            <Button to="/who-we-are" type="get_know_us"/>

                            <div className="col-md-5">
                                <ul className="iso-icons">
                                    <li>
                                        <img src={iso_logo_01} alt="" title="" />
                                    </li>
                                    <li>
                                        <img src={iso_logo_02} alt="" title="" />
                                    </li>
                                </ul>
                            </div>
                            
                        </div>
                        
                   
                </div>
            </div>


            <div className="team-count" data-theme="light">
                <div className="container">
                    <ul className="count-list">
                        <li>
                            <b className="value">100+</b>
                            <span className="label">Team Members &amp; Counting</span>
                        </li>
                        <li>
                            <b className="value">04</b>
                            <span className="label">Offices Packed with creativity</span>
                        </li>
                        <li>
                            <b className="value">10+</b>
                            <span className="label">Live Products in Market</span>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Our Clients */}
            
            <div className="our-clients"  data-theme="light" >

                <div className="container">

                    <div className="row">
                        <div className="col-md-6">
                            <h5 className="head">Our Happy Clients</h5>
                            <p className="section-text">
                                At our core, we're a team of enthusiastic professionals who take
                                pride in delivering exceptional services to our amazing clients.
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <ul className="clients-list">
                            <li>
                                <img src={client_logo_01} alt="" title="" />
                            </li>
                            <li>
                                <img src={client_logo_02} alt="" title="" />
                            </li>
                            <li>
                                <img src={client_logo_03} alt="" title="" />
                            </li>
                            <li>
                                <img src={client_logo_04} alt="" title="" />
                            </li>
                            <li>
                                <img src={client_logo_05} alt="" title="" />
                            </li>
                        </ul>
                    </div>

                </div>

            </div>

        </>
    );
}

export default AboutFreston;