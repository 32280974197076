import React from 'react';
import Layout from '../../components/layout';
import LetConnect from '../../components/common/lets-coonect';

import kaller_kcloud from "../../assets/images/products/Nexus 360 banner.png"
import process_manager from "../../assets/images/products/PM---Banner.jpg"
import safer_circle from "../../assets/images/products/Safer-Banner.png"

import brand_01 from "../../assets/images/brand-01.png"



export const products=[
    {
    image: kaller_kcloud,
    label:`Nexus360`,
    description:`Easily connect popular business and CRM apps to your contact centre, business phone, video meetings and team chat. Increase the productivity of your teams without complexity or cost.`,
    link:`/our-product-kaller-cloud`,
    shortline:'Easily connect popular business and CRM applications'
  
    },
    {
      image: process_manager,
      label:`Process Manager`,
      description:`Complex projects or day-to-day tasks, Process Manager simplifies and enhances task execution, making it an essential tool for modern workflow management.`,
      link:`/our-product-process-manager`,
      shortline:'Complex projects or day-to-day tasks, Process Manager simplifies and enhances task execution'
    },
    {
      image: safer_circle,
      label:`Safer Circle`,
      description:`The Safer Circle application act as a citizen guard. leveraging the power of community engagement and advanced technology.`,
      link:`/our-product-safer-circle`,
      shortline:'The Safer Circle application act as a citizen guard'
    },
  ]

export const ProductItem=(props)=>{

    return(
        <a href={`${props?.product?.link}`}>
        <div className="item" >
        <div className="top">
        <div className="image">
        <img src={props?.product?.image} alt="" />
        </div>
        <div className="description">
            {props?.product?.description}
        </div>
        </div>
        <div className="head">
        <h2 className="name">{props?.product?.label}</h2>
        <p className="text">{props?.product?.shortline}</p>
        </div>
        </div>
        </a>
    )
}

const metatag={
    "title":"Our Products"
}

const OurProducts = () => {

    return(
        <Layout page="our-product" metatag={metatag}>

            <article className='our-product'   style={{flexDirection:'column',width:'100%'}}>

                <section className="main-section" data-theme="dark"   background-color='#0B1F40' >
                    <div className="container two">
                    <div className="row">
                        <div className="col-md-12">
                        <h2 className="section-title">
                            "Experience the Future of Technology with <br /> Our Digital
                            Products"
                        </h2>
                        <h4 className="text">
                            Trust us, we got everything you need to <br /> tune up your money
                        </h4>
                        
                         <div className="brands-section">
                            {
                                products.map((item)=>{
                                    return(
                                        <div className="brands">
                                        <span className="logo">
                                            <img src={brand_01} alt="" />
                                        </span>
                                        <span className="content">
                                            <span className="name">{item.label}</span>
                                            <span className="category">{item.shortline}</span>
                                        </span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                 
                        </div>
                    </div>
                    </div>
                </section>

                <section className="product-section" data-theme="light">
                    <div className="section-container">
            
                    <div className="product-item-list">
                        
                        <div  className='even-list' >
                            {
                                products.map((product,index)=>{
                                    if(index %2 === 0 ){
                                        return(
                                            <ProductItem product={product} index={index} />
                                        )
                                    }

                                })
                            }
                        </div >

                        <div  className='odd-list' >
                            {
                                products.map(( product, index )=>{
                                    if(index %2 !== 0 ){
                                        return(
                                            <ProductItem product={product} index={index} />
                                        )
                                    }

                                })
                            }
                        </div >

                    </div>
            
                    </div>
                </section>

                <LetConnect darkicon to="/contact-us" title="Let us know how we can assist you" buttonlabel="LET'S CONNECT" />
                
            </article>

        </Layout>
    )
    
}
 
export default OurProducts;