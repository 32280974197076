import React from 'react';
import Layout from '../../components/layout';

import LetConnect from '../../components/common/lets-coonect';
import {products,ProductItem} from './index'

import product_0 from "../../assets/images/products/Nexus 360 innerpage top.png"
import product_4 from "../../assets/images/products/Nexus 360 center.png"
import product_5 from "../../assets/images/products/Nexus 360 left.png"
import product_6 from "../../assets/images/products/Nexus 360 right.png"

import icon_01 from '../../assets/images/icon-contact-centre.png'
import icon_02 from '../../assets/images/icon-International-business-phone.png'
import icon_03 from '../../assets/images/icon-quick-call-widget.png'
import icon_04 from '../../assets/images/icon-video-meetings.png' 
import icon_05 from '../../assets/images/icon-communication-api.png'


import platform from '../../assets/images/kaller-kloud-platform.png'

import tick_icon from "../../assets/images/icon-tick.png"


import './our-product.scss'
import Button from '../../components/common/button';

const metatag={
    "title":"Our Products"
}

const KallerCloud = () => {


    return(
        <Layout page="inner-product"  metatag={metatag}>

            <article className='inner-product' >
               
              <section className='introduction-section' data-theme={'light'} >

                <div className='section-container' >

                    <div className='container-content' >

                        {/* <p className='label' >INTRODUCTION</p> */}

                        <p className='title' >  About   <span > Nexus 360 </span> </p>

                        <div className='container-image' >
                            <div className='image-container' >
                                <img className='display-image' src={product_0} alt="" />
                            </div>
                        </div>

                        <p className='description'> All in one unified communications and
contact centre solution. it is very easily connect popular business and CRM apps to your contact centre, business phone, video meetings and team chat. Increase the productivity of your teams without complexity or cost. </p>
                        
                        <div className='bullet-point-container'>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src={tick_icon} alt="" />
                                        </div>
                                        <div className='bullet-text' >Our network is backed by platform-wide 99.999% uptime SLA as well as public and private data centres.</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src={tick_icon} alt="" />
                                        </div>
                                        <div className='bullet-text' > Easily connect popular business and CRM apps to your contact centre, business phone, video meetings and team chat.</div>
                                    </div>
                        </div>

                        <img src={platform} className='platform' alt="" ></img>

                        {/* <ButtonLink to="/home/ai-operation-solution" label="Lean More" labelcolor="black" /> */}

                    </div>

                    <div className='container-image' >
                        <div className='image-container' >
                            <img className='display-image' src={product_0}  alt="" />
                        </div>
                    </div>

                </div>

              </section>

              <section className='contact-center-solution' data-theme="light" >
                        <div  className='section-container'>

                            <p className='title'> <span> All-in-one Unified Communications and  </span>  <br/>  Contact Centre Solution  </p>

                            <div className='card-container' >

                                <div className='card' >
                                    <img className='card-icon' src={icon_01} alt="" />
                                    <p className='title' >Contact Centre:</p>
                                    Wow customers and arm employees with a solution that allows seamless communication across platforms and devices.
                                </div>

                                <div className='card' >
                                    <img className='card-icon' src={icon_02} alt="" />
                                    <p className='title' >International business Phone:</p>
                                    No matter where your business is based, get local numbers and global connectivity from one easy app. 
                                </div>

                                <div className='card' >
                                    <img className='card-icon' src={icon_03} alt=""/>
                                    <p className='title' >Quick Call Widget :</p>
                                    Enhance customer service by adding a call widget to your website and auto route to the right departments. 
                                </div>

                            </div>

                            <div className='card-container' >

                                    <div className='card' >
                                        <img className='card-icon' src={icon_04} alt=""/>
                                        <p className='title' >Video Meetings :</p>
                                        Amp up your virtual meeting experience with HD videos and easy screen sharing on desktop and mobile. 
                                    </div>

                                    <div className='card' >
                                        <img className='card-icon' src={icon_05} alt=""/>
                                        <p className='title' >Communication APIs </p>
                                        Let your team enjoy the comfort of familiarity by integrating your platform with SMS, voice, video, and chat apps of your choice 
                                    </div>

                            </div>

                        </div >
              </section>

              <section className="product-section" data-theme="light" >

                <div className="section-container"  >
                    <p className='description'> At the heart of Kaller Kloud lies its innovative communication widget. This sleek and seamlessly integrated tool can be effortlessly placed on any website, instantly transforming it into a dynamic hub for customer engagement. Imagine a world where your customers can reach out to you with a simple click, making inquiries, seeking assistance, or offering valuable feedback - all without leaving your website.</p>
                    <div className='inner-container' >
        
                    <img className='image-one' src={product_4} alt=""></img>
                    <div className='image-container' >
                    <img className='image-two' src={product_5}  alt="" ></img>
                    <img className='image-two' src={product_6}  alt="" ></img>
                    </div>
                    
                    </div>
                    <p className='description'> Kaller Kloud effortlessly integrates with your existing systems, simplifying the setup process. Whether you operate an e-commerce platform, run a blog, or manage a corporate website, KallerKloud adapts to your unique requirements, ensuring a smooth transition to enhanced customer engagement. </p>
                </div>

              </section>

              <section className='explore-other-products-section' data-theme="light" > 

                <div className='section-container'>
                    
                    <div className='inner-container' >
                        <div className='head' ><p>EXPLORE OTHER PRODUCTS</p>
                                 <Button to="/our-products" type="button_more_products" />
                        </div>
                        <div className='body' >
                        <div className='image-container' >
                            <ProductItem  product={products[2]} />
                            <ProductItem  product={products[1]} />
                        </div>
                        </div>
                    </div>
                
                </div>

              </section>

              <LetConnect darkicon to="/contact-us" title="Okay enough about us, Lets talk about you" buttonlabel="LET'S CONNECT"/>
                
            </article>

        </Layout>
    )

}
 
export default KallerCloud;