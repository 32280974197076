import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../pages/home/index'
import WhoWeAre from '../pages/who-we-are'
import ContactUs from '../pages/contact-us'


import OurIndustries from '../pages/our-industries';
import OurServices from '../pages/our-services';

import OurProduct from '../pages/our-product'
import SaferCircle from '../pages/our-product/safer-circle'
import ProcessManager from '../pages/our-product/process-manager'
import KallerCloud from '../pages/our-product/kaller-cloud'

import Gpdr from '../pages/gpdr'
import TermsOfUse from '../pages/terms-of-use'
import CookiePolicy from '../pages/cookie-policy'

const Router = () => {
  
  return (

      <Routes>

        <Route
                path={'/'}
                element={<Home />} />

        <Route
          path={'/who-we-are'}
          element={<WhoWeAre />}
        />

        <Route
          path={'/contact-us'}
          element={<ContactUs />}
        />


        <Route
          path={'/our-industries'}
          element={<OurIndustries />}
        />

        <Route
          path={'/our-services'}
          element={<OurServices />}
        />

        <Route path={'/our-products'}  element={<OurProduct />} />
        <Route path={'/our-product-safer-circle'}  element={<SaferCircle />} />
        <Route path={'/our-product-process-manager'}  element={<ProcessManager />} />
        <Route path={'/our-product-kaller-cloud'}  element={<KallerCloud />} />

        <Route
          path={'/gpdr'}
          element={<Gpdr />}
        />

        <Route
          path={'/terms-of-use'}
          element={<TermsOfUse />}
        />

        <Route
          path={'/cookie-policy'}
          element={<CookiePolicy />}
        />

      </Routes>

  );
  
};

export default Router;