import React from 'react';

import {Link} from "react-router-dom"

import get_know_us from "../../assets/images/button/button-color-get-know-us.png"
import button_connect from "../../assets/images/button/button-connect.png"
import button_lets_connect from "../../assets/images/button/button-let-connect.png"
import explore_services from "../../assets/images/button/button-trans-explore-services.png"
import explore_product from "../../assets/images/button/button-trans-explore-product.png"
import more_products from "../../assets/images/button/button-trans-more-products.png"
import back_to_home from "../../assets/images/button/button-trans-back-to-home.png"



export const Button=(props)=>{

    switch(props?.type){
        case "get_know_us":
            return (
                <Link to={`${props?.to||""}`}>
                    <button style={{background:`url(${get_know_us})`,backgroundPosition:'center',backgroundSize:'cover'}}  className="get_know_us"   />
                </Link>
            )
        case "button_connect":
            return (
                <Link to={`${props?.to||""}`}>
                   <button style={{background:`url(${button_connect})`,backgroundPosition:'center',backgroundSize:'cover'}} className="button_connect"  />
                </Link>
            )
        case "button_more_products":
            return (
                <Link to={`${props?.to||""}`}>
                   <button style={{background:`url(${more_products})`,backgroundPosition:'center'}} className="button_more_products"  />
                </Link>
            )
            
        case "button_lets_connect":
            return (
                <Link to={`${props?.to||""}`}>
                   <button style={{background:`url(${button_lets_connect})`,backgroundPosition:'center'}} className="button_lets_connect" />
                </Link>
            )
        case "explore_services":
            return (
                <Link to={`${props?.to||""}`}>
                    <button style={{background:`url(${explore_services})`,backgroundPosition:'center'}} className="explore_services"  />
                </Link>
            )
        case "explore_product":
            return (
                <Link to={`${props?.to||""}`}>
                   <button style={{background:`url(${explore_product})`,backgroundPosition:'center'}} className="explore_product" />
                </Link>
            )
        case "back_to_home":
            return (
                <Link to={`${props?.to||""}`}>
                   <button style={{background:`url(${back_to_home})`,backgroundPosition:'center'}} className="explore_product" />
                </Link>
            )
        default:
            return (
                <Link to={`${props?.to||""}`}>
                    <button style={{background:`url(${get_know_us})`,backgroundPosition:'center'}}  className="get_know_us"   />
                </Link>
            )
    }

    // return(
    // <button className='button' {...props}>
    //     Get to know us 
    //     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    //     <path d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z" fill="white"/>
    //     </svg>
    // </button> 
    // )

};

export default Button