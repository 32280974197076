
import SimpleImageSlider from "react-simple-image-slider";
import { useEffect } from "react";
import { useState } from "react";

const images = [
  { url: "assets/images/background_01.png" },
  { url: "assets/images/background_02.png" },
  { url: "assets/images/background_03.png" }
];

const MainBanner = () => {

 const title = [<>Accelerate Your <br/> Digital <br/> Transformation with <br/> Mobisense</>,<>Harness Big Data <br/> Insights for <br/> Transformative <br/> Growth</>,<>Leading 5G <br/> Innovators: <br/> Mobisense Drives Your <br/>Digital Future</>]

 const [state,setstate]=useState(<p  className="title"  >{title[0]}</p>)
  useEffect(()=>{

    const el = document.querySelector('[data-id="bullet-0"]');
    el.addEventListener('click',()=>{


      setstate(<p  className="title"  >{title[0]}</p>)

    })

    const bl = document.querySelector('[data-id="bullet-1"]');
    bl.addEventListener('click',()=>{

      setstate(<p  className="title"  >{title[1]}</p>)

    })


    const cl = document.querySelector('[data-id="bullet-2"]');
    cl.addEventListener('click',()=>{

      setstate(<p className="title"  >{title[2]}</p>)

    })

  })

  return(
    <div>
    {state}
     <SimpleImageSlider
        width={'100vw'}
        height={'100vh'}
        images={images}
        showBullets={true}
        showNavs={false}
        loop={true}
        // autoPlay={true}
        // autoPlayDelay={5}
      />
  </div>
  )

    // return (
    // <div className="container">
    //   <div className="row">

    //     <div className="col-md-12">
    //       <div className="content">
    //         <h1 className="title">
    //         Accelerate Your Digital Transformation with Mobisense
    //         </h1>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    //  );
    
}
 
export default MainBanner;