import React from 'react'
import logo from '../../assets/logo/footer-logo-color.png'
import { Link } from 'react-router-dom'

import social_01 from "../../assets/images/social-01.svg"
import social_gallery_01 from "../../assets/images/social-01-gallery.svg"
import social_02 from "../../assets/images/social-02.svg"
import social_gallery_02 from "../../assets/images/social-02-gallery.svg"
import social_03 from "../../assets/images/social-03.svg"
import social_gallery_03 from "../../assets/images/social-03-gallery.svg"
import social_04 from "../../assets/images/social-04.svg"
import social_gallery_04 from "../../assets/images/social-04-gallery.svg"

const date = new Date();


const Footer= ()=>{

  const footerLink=[
    [
      {label:'Our Service',link:'#'},
      {label:'IT Consulting Service',link:'/our-services#IT Consulting Service'},
      {label:'Managed IT Service',link:'/our-services#Managed IT Service'},
      {label:'Cloud Service',link:'/our-services#Cloud Service'},
      {label:'Annual Maintenance Service',link:'/our-services#Annual Maintenance Service'},
      {label:'Resource Augmentation',link:'/our-services#Resource Augmentation'},
    ],
    [
      {label:'Join Us',link:'#'},
      {label:"Contact Us",link:'/contact-us'},
    ]
  ]


return(
  <footer className='footer-section' > 

  <div className='section-container'>
    <div className='inner-container'>
        <div className='footer-top'>
            <div className='logo-container'>

          <img
            src={logo}
            alt=""
            className="footer-logo"
          />

          <h2 className="footer-title">
            We Make <br />
            Digital Transformation
          </h2>


            </div>
            <div className='link-container'>
              <div>
          {
            footerLink.map((item,index)=>{
              return(
                <ul className="footer-list" key={index}>
                  
                  {
                    item.map((link,index)=>{
                      if(index===0){
                        return(
                          <h5 className="head" key={index}>{link.label}</h5>
                        )
                      }else{
                        return(
                          <li key={index}>
                            <Link to={`${link.link}`}>{link.label}</Link>
                          </li>
                          )
                      }
                    })
                  }

              </ul>
              )
            })
          }
              </div>
            </div>
        </div>
        <div className='footer-bottom'>

          <div className='socialmedia-container'>

          <ul className="footer-social" >
            <li>
              <a href="https://www.instagram.com/frestonanalytics" target="_blank" rel="noreferrer" >
                <img className='default' src={social_01} alt="" />
                <img className='active' src={social_gallery_01} alt="" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/frestonanalytics" target="_blank" rel="noreferrer" >
                <img className='default' src={social_04} alt="" />
                <img className='active' src={social_gallery_04} alt="" />
              </a>
            </li>
            <li>
              <a  href="https://www.facebook.com/frestonanalytics" target="_blank" rel="noreferrer" >
                <img className='default' src={social_03} alt="" />
                <img className='active' src={social_gallery_03} alt="" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/frestonanalytic" target="_blank" rel="noreferrer" >
              <img className='default' src={social_02} alt="" />
                <img className='active' src={social_gallery_02} alt="" />
              </a>
            </li>
          </ul>
          
          </div>
          
          <div className='footer-bar'></div>

          <div className="copyright-container">
                <div >
                  <p className="copyright">
                    © {date.getFullYear()} Mobisense All Rights Reserved.
                  </p>       
                </div>
                <div >
                <ul>
                    <li>
                      <a href="/gpdr">GDPR</a>
                  </li>
                  <li>
                      <a href="/terms-of-use">Terms of Use</a>
                    </li>
                  <li>
                      <a href="/cookie-policy">Cookie Policy</a>
                    </li>
                  </ul>
                </div>
          </div>

        </div>
    </div>
  </div>

  </footer>
)
}
 
export default Footer;