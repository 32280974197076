import React from 'react';
import Layout from '../../components/layout';

import LetConnect from '../../components/common/lets-coonect';

import {products,ProductItem} from './index'

import product_0 from "../../assets/images/products/PM---inner-page.png"
import product_4 from "../../assets/images/products/PM---large.jpg"
import product_5 from "../../assets/images/products/PM---left.jpg"
import product_6 from "../../assets/images/products/PM---right.jpg"


import './our-product.scss'
import Button from '../../components/common/button';

const metatag={
    "title":"Our Products"
}

const ProcessManager = () => {


    return(
        <Layout page="inner-product"  metatag={metatag}>

            <article className='inner-product' >

             {/* <DispalySection title={<>Leading the Way in Diverse Industries:<br /> Our Commitment to Innovation and Excellence</>} subtitle={<>From strategy to execution, we help you achieve success</>}/> */}
               
              <section className='introduction-section' data-theme={'light'} >

                <div className='section-container' >

                    <div className='container-content' >

                        {/* <p className='label' >INTRODUCTION</p> */}

                        <p className='title' > About   <span > Process Manager </span> </p>
                        <div className='container-image' >
                            <div className='image-container' >
                                <img className='display-image' src={product_0} alt="" />
                            </div>
                        </div>

                        <p className='description'> The Process Manager application is a robust tool designed to optimize communication and collaboration between Admin, agents, and Subscribers. This user-friendly application is built around three core components: Tasks, Processes, and Steps. Through an intuitive interface, Process Manager empowers users to efficiently manage intricate workflows, monitor progress, and communicate seamlessly with agents. Its structured approach enhances productivity, ensuring a well-organized experience for both users and agents throughout the entire process lifecycle. Whether handling complex projects or day-to-day tasks, Process Manager simplifies and enhances task execution, making it an essential tool for modern workflow management. </p>

                        {/* <div className='bullet-point-container'>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src={tick_icon} alt="" />
                                        </div>
                                        <div className='bullet-text' > Citizens to report incidents or suspicious behaviour to the agents.</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src={tick_icon} alt="" />
                                        </div>
                                        <div className='bullet-text' > Respond to and prevent crime by providing real-time information.</div>
                                    </div>
                        </div> */}

                        {/* <ButtonLink to="/home/ai-operation-solution" label="Lean More" labelcolor="black" /> */}

                    </div>

                    <div className='container-image' >
                        <div className='image-container' >
                            <img className='display-image' src={product_0}  alt="" />
                        </div>
                    </div>

                </div>

              </section>

              <section className="product-section" data-theme="light" >

                <div className="section-container"  >
                    <p className='description'> Tasks serve as the primary organizational units, representing overarching goals or objectives. Each Task encompasses multiple Processes, which outline specific actions necessary to achieve the Task's goals. Within each Process, individual Steps are meticulously defined, guiding users and agents through required actions and interactions.
                            Steps, within the "Process Manager" app, signify individual actions or tasks that must be sequentially completed for successful Process advancement. A Process comprises several Steps, collectively guiding users and agents toward achieving the associated Task's objectives </p>
                    <div className='inner-container' >
        
                    <img className='image-one' src={product_4} alt="" ></img>
                    <div className='image-container' >
                    <img className='image-two' src={product_5} alt="" ></img>
                    <img className='image-two' src={product_6} alt="" ></img>
                    </div>
                    
                    </div>
                    <p className='description'> The chat feature in the "Process Manager" app offers a powerful communication channel for seamless interaction among users, agents, and admin. This real-time chat system facilitates instant messaging and communication. Users, agents, and admin can exchange messages, updates, and important information within the context of specific Tasks, Processes, or Steps. The chat interface supports real-time communication through text, audio, and video messages. This effortless collaboration allows users, agents, and administrators to exchange information, files, and media, promoting efficient task execution and smooth coordination. </p>
                </div>

              </section>

              <section className='explore-other-products-section' data-theme="light" > 

                <div className='section-container'>
                    
                    <div className='inner-container' >
                        <div className='head' ><p>EXPLORE OTHER PRODUCTS</p>
                      <Button to="/our-products" type="button_more_products" />
                        </div>
                        <div className='body' >
                            <div className='image-container' >
                                <ProductItem  product={products[0]} />
                                <ProductItem  product={products[2]} />
                            </div>
                        </div>
                    </div>
                
                </div>

              </section>

              <LetConnect darkicon to="/contact-us" title="Okay enough about us, Lets talk about you" buttonlabel="LET'S CONNECT"/>
                
            </article>

        </Layout>
    )

}
 
export default ProcessManager;